
.vip_info {
    text-align: center; 
}
.vip-wrapper {  
    .vip_row{
        background-color: #5949C4;
        padding: 2px 0;
        margin: 15px -15px 30px;
    }
}


.vip-wrapper{
    img.vip_current{ 
        margin: 30px 0; 
    }
    .vip_info{
        font-family: 'Urbanist', sans-serif; 
        color:#fff;
        h1{
            font-weight:900;
            font-size:24px;
            text-transform: uppercase;
        }
        h4{
            font-weight:bold;
            font-size:12px
        }
        .vip_progress{
            margin: 30px auto 0;
            width: 80%;
        }
        .vip_progress_info{
            display: flex;
            justify-content: space-between;
            font-family: 'Urbanist', sans-serif; 
            font-size:10px;
            font-weight:900;
            span{
                margin:5px 0;
            }
        }
    }
}

.vip_curr{
    display: flex;
    justify-content: space-around;
    font-family: 'Urbanist', sans-serif; 
    font-size:12px;
    font-weight:900;
    color: #fff; 
    .vip_curr_info{
        margin:5px 0;
        align-items: center;
        display: flex;
        justify-content: flex-start;
        span{
            margin-left:5px;
            letter-spacing: 1.5px;
        }
    }
}
.vip-item{
    border-radius: 20px;
    padding:15px 30px;
    background-color: #222123;
    color:#fff;
    font-family: 'Urbanist', sans-serif; 
    margin-bottom:15px; 
    overflow: hidden;
    .vip_curr{
        background-color: #00000082;
        margin: 0 -30px -15px;
        padding:5px 0;
    }
    .vip_heading{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:20px 0;
        h4{ 
            letter-spacing: 2.6px;
            font-family: 'Urbanist', sans-serif; 
            font-weight: 600;
            font-size: 20px;
            margin-left:10px;
            text-transform: uppercase;
        }
    }
    &:nth-child(1){ 
        background-color: #4FD2C1;
    }
    &:nth-child(2){ 
        background-color: #6164CF;
    }
    &:nth-child(3){ 
        background: rgb(254,241,99);
        background: linear-gradient(156deg, rgba(254,241,99,1) 0%, rgba(255,20,87,1) 100%);
    }
}

.quant_wrapper{
    color:#fff;
}

.accoun-balance{
    background-color: #F6F6F6;
    border-radius: 15px;
    padding:20px;
    margin: 0 0 20px;
    .account-balance-info{ 
        font-family: 'Urbanist', sans-serif; 
        .account-balance-header{  
            h4{ 
                font-size: 16px;
                margin:0;
            }
            span{
                color: #fff;
                font-size: 80%;
            }
        }
        h1{  
            font-size: 32px;
            margin:20px 0;
        }
    }
}
.quant-action{ 
    text-align: center;
    .btn{
        color: #fff;
        display: inline-block;
        width: 45%;
        border-color: #fff; 
        margin:0 5px;
    }
}
.account-summary{
    background-color: #fff;
    border:1px solid #00000012;
    border-radius: 15px;
    padding:20px;
    margin: 0 0 20px;
    font-family: 'Urbanist', sans-serif; 
    .account-summary-info{
        display: flex;
        justify-content: space-between;
        margin-bottom:30px;
        h4{
            color:#1A1C1E;
            font-weight: bold;
            font-size: 18px;
            margin:0;
        }
    }
    .more-link{ 
        font-size: 14px;
        color:#ACB5BB;
        i{
            margin-left:5px;
        }
    }
    .order-list-items{
        background-color: transparent;
        padding: 5px 0;
    }
    .order-list-item-value{
        margin:0;
        font-weight: bold;
    }
}
.order-action{
    margin: 30px 0px;
    .btn-primary{
        padding:10px;
        border-radius: 15px;
    }
}