.nav-footer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    color: $theme-secondary-color;
    background: $theme-primary-color;  
    z-index: 999;
    height: 60px;
    display:flex;
    justify-content: space-around;
    
    width: 475px;
    margin: 0 auto;
    border-top: 1px solid rgb(238 234 234);
    border-left: 1px solid rgb(238 234 234);
    border-right: 1px solid rgb(238 234 234);
}
.nav-footer-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    opacity:0.5;
    span{
        font-size: 14px;
        text-align: center;
    }
    &.active {  
        background: rgb(39,203,192);
        background: linear-gradient(164deg, rgba(39,203,192,1) 0%, rgba(4,99,239,1) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-weight: bold;
        opacity:1;
    }
} 