.withdraw-wrapper{
    font-family: 'Urbanist', sans-serif; 
    .card{
        height: 210px;
        width: auto;
        margin: 0 auto 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 50px;
        position: relative;
        .balance_info{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #fff;   
            width: 100%;
            position: relative;
            margin-top:50px;
            h2{
                font-size: 40px;
                font-weight:bold;
            }
            h4{
                font-size: 14px;
                font-weight:normal;
            }
        }         
        .balance-note{
            color: #F7FBFF; 
            font-size: 10px;
            margin-top: 20px;
            display: block;
            width: 100%;
        }
        .btn-withdrawall{
            background-color: #fff;
            color: #0B75E8;
            border-radius: 8px;
            padding:5px;
            font-size: 10px;
            font-weight:500;
        }
    } 
    label{ 
        font-weight:600!important;
        font-size: 16px;
    }
    .input-withdraw{
        background-color: #F5F5F5;
        padding:15px 20px;
        border-radius:15px;
        display: flex;
        .label_currency{
            display: flex;
            justify-content: center;
            align-items: center;
            span{ 
                background-color: #0B75E8;
                border-radius:30px;
                padding:10px 15px;
                color:#fff;
                margin-right:10px;
                font-size: 10px;
                font-weight:bold;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img{
                margin-right:5px;
            }
        }
        .input_box{ 
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .input_prefix{
                font-size: 32px;
                color:#000;
                font-weight:bold;
            }
            input{
                margin-left:5px;
                font-size: 32px;
                width:100%;
                background: transparent;
                border: none;
                color: #000;
                &:focus-visible {
                    outline: none;
                }
            }
        }
    }
    .input-currency{
        display: flex;
        .input-curr-type{
            width: 20%;
            img{
                max-height: 50px;
            }
            &.disabled{ 
                filter: grayscale(100%) opacity(0.6);
            }
            &.active{
                img{
                    border:4px solid #0B75E8;
                    border-radius:15px;
                    overflow: hidden;
                }
            }
        }
    }
    .form-control{
        background-color: #F6F6F6;
        border: none;
        color: #000;
        padding:15px;
        &:focus-visible {
            outline: none;
        }
        &.password{ 
            padding:5px 15px;
            font-size: 32px;
            letter-spacing: 10px;
        }
    }
}