.content-page,
.login-page {   
    text-align: center;
    flex: 1;
    display: flex;
    padding-bottom: 60px;
    .header{
        background: rgb(254,241,99);
        background: linear-gradient(164deg, rgba(254,241,99,1) 0%, rgba(255,20,87,1) 100%);
        display: block;
        padding:30px 0;
        border-radius: 0 0 15px 15px;
        h4{
            font-family: 'Urbanist', sans-serif;
            color:#fff;
            margin:20px 0;
        }
    } 
}
.nav-reg{
    justify-content: space-between;
    display: flex;
    margin: 20px 0 50px 0;
    color: #999999; 
    .nav-link{  
        display: inline;
        cursor: pointer;
    }
}
.form-info{
    background-color: #fff;
    border-radius: 15px;
    margin-top: -30px;
    padding: 40px 20px;  
    box-shadow: 0px 0px 9px 6px #76767614;
    font-family: 'Urbanist', sans-serif;
    .btn-primary{ 
        padding:10px 20px;
    }
    label{
        font-weight: bold;
        color:#000;
    } 
}
.input-terms{
    margin-top:15px;
    position:relative;
    text-align: left; 
}
.register-page{
    .form-info{ 
        margin-top: 20px;
    }

}