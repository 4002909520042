// main.scss
$theme-primary-color: #fff;
$theme-primary3-color: #fff;
$theme-primary2-color: #fff;
$theme-secondary-color: #000;

body {
 background-color: $theme-primary-color!important;
 color: $theme-secondary-color;
 font-family: 'Urbanist', sans-serif; 
}

.loading{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
    background: #202020c9;
    color:#fff;
    span{ 
        position: absolute;
        z-index: 9999;
        left: 50%;
        top: 50%;
        text-align: center;
        font-size:14px;
        i{ 
            font-size: 26px; 
            color:#fff
        }
    }
}
.loading span{  
    position: absolute;
    left: 50%;
    top: 50%; 
    margin-left: -20px;
    margin-top: -20px;
}
.wrapper{ 
    position: relative;
    width: 475px;
    margin: 0 auto;
    border-left: 1px solid rgb(238 234 234);
    border-right: 1px solid rgb(238 234 234);
    min-height: 100vh;
    background-color: #f8f7f5;
    overflow: auto; 
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: top;
}
.form-info{
    width: 320px;
    max-width: 390px;
    margin: 0 auto;
}
.form-group{
    margin-bottom:15px;
}
.form-action{
    margin-top:20px;
}
.justify-between{
    display: flex;
    justify-content: space-between;
}
.form-flex {
    display: flex;
    align-items: center;
}
.MuiFormControl-root{
    width: 100%;
}
.btn-danger,
.btn-primary{
    width: 100%;
}
.btn-danger{
    background-color: #f0f0f0!important;
}
.btn-danger,
.btn-danger:hover{
    color: #000!important;
    border-color: #000!important;
}

.page-wrapper{
    padding:60px 15px 60px;
    width: 100%;
    &.info-wrapper{ 
        padding:60px 15px 0;
        .app_wrapper{
            padding:15px 0;
        }
    }
    &.mining-wrapper{
        padding-top:0;
    }
    &.my-wrapper{
        padding:40px 15px 60px; 
    }
}
.coin-lists{
    background-color: #fff;
    padding:10px;
    border-radius: 10px;
    margin: 10px 0;
    table{
        th{
            font-weight:normal;
            color: #b3b0bf;
            font-size:14px;
            text-align: center;
        }
        td{
            text-align: center;
        }
    }
    span{ 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .coin-header{ 
        display: flex;
        justify-content: flex-start; 
        .coin-title{
            margin-left:10px;
            font-weight:bold;
            font-size:14px;
        }
    }
    .coin-price{
        color:#644FDD;
        font-weight:bold;
        font-size:14px;
        padding:3px 5px;
    }
    .coin-change{
        font-size:14px;
        font-weight:bold;
        display: inline-block;
        width:80px;
        padding:3px 5px;
        &.green{
            color:#644FDD;
            background: #EEEBFC;
        }
        &.red{
            color:#d00;
            background: #ff9a9a;
        }
    }
}
.drawer-header{
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin:30px 0;
    .drawer-action{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .drawer-info{ 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .drawer-avatar{
            margin-right:10px;
        }
        .drawer-title{
            h4{
                font-size: 16px;
            }
            h5{
                font-size: 14px;
                color: #969799;
            }
            h3{
                font-size: 21px;
                font-weight: bold;
            }
        }
    }
    .drawer-copy-btn{
        margin-left:5px;
    }
}
.mamber-nav{
    .list-group-item{
        display: flex;
        justify-content: space-between;
        padding:15px 10px;
        .btn-title{
            i{
                margin-right:5px;
                width:25px;
            }
        }
    }
}
.home-nav{
    background-color: #fff;
    padding:10px;
    border-radius: 10px;
    margin: 10px 0;
    box-shadow: 0px 2px 6px 1px #00000024;
    display: flex;
    justify-content: space-between;
    .home-nav-item{ 
        display: flex;
        flex-direction: column;
        span{
            font-size:12px;
            text-align: center;
            font-weight:bold;
            padding-top:5px;
        }
    }
}
.top-header{

    .inner-top-nav{  
        max-width: 475px;
        margin: 0 auto;
        border-left: 1px solid rgb(238, 234, 234);
        border-right: 1px solid rgb(238, 234, 234);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: $theme-primary-color;
        color: $theme-secondary-color; 
        text-align: left;
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        align-items: center;  
        z-index: 999;
        .btn-back{
            background-color: #F6F6F6;
            width:30px;
            height: 30px;
            border-radius: 50%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-right:5px;
            color:#644FDD;
        }
        .nav-title{
            margin-left:-30px;
        }
        .top-nav-right{
            display: flex;
        }
    }
    h1{
        margin:50px 0;
        font-size: 35px;
        font-weight: 700;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        color:#373737
    }
}
.mining-wrapper{
    position: relative;
    background-color: #fff;

    .mining_summary{
        background-position: center;
        background-size: cover;
        color: #fff;
        height: 320px;
        margin: 0 -15px;
        padding: 30px;
    }
    .mining_detail{ 
        background-color: #644FDD;
        color: #fff; 
        margin: 0 -14px;
        padding: 30px 30px 0;
        .mining_detail_top{ 
            margin-top: 50px;
            .mining_detail_header{
                &.right{
                    text-align: right;
                }
                .mining_title{
                    display: flex;
                    align-items: center;
                    line-height: 100%;
                    span{
                        margin-left: 10px;
                    }  
                }
            }
        }
        .mining_detail_info{
            display: flex;
            justify-content: space-between;
            h4{
                font-size:20px;
                margin: 5px 0;
            }
            h5{
                font-size:20px;
                color: #ffcb71;
                margin:0;
            }
        }
        .mining_detail_bottom{        
            background: #5844c7;
            margin: 30px -28px 0;
            padding:15px;
            .item_value{
                color:#fff;
                font-size: 21px;
                font-weight: normal;
            }
            .item_title{
                color:#86a4f2;
                font-size: 14px;
                margin: 0;
            }
        }
    }
    .mining_form{ 
        .mining_form_row{
            background-color: #fff;
            padding:10px;
            margin:30px 0 15px 0;
            border-radius: 10px;
            box-shadow: 0px 0px 2px 1px #0000001f;
            .item_title{ 
                color: #9ba7b2;
                font-size: 16px;
                margin:0;
                height: 40px;
                align-items: center;
                display: flex;
                font-weight: normal;
            }
            .item_value{
                font-weight: normal;
                font-size: 16px;
                height: 40px;
                align-items: center;
                display: flex;
                justify-content: center;
                line-height: 100%;
                margin:0;
            }
            .left{
                .item_value,
                .item_title{
                    justify-content: flex-start;
                    padding:0 10px;
                    text-align: left;
                }
            }
            .right{
                .item_value,
                .item_title{
                    justify-content: flex-end;
                    padding:0 10px ;
                    text-align: right;
                }
            }
            .mining_form_input{
                border:1px solid #f0f0f0;
                padding:5px 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .input_label{              
                    color: #9ba7b2; 
                    border-right: 1px solid #9ba7b2;
                    padding-right:5px; 
                    margin-right:5px; 
                    font-size: 14px;
                }
                .input_btn{              
                    color: #000; 
                    font-size: 14px;
                }
                input{
                    border:none;
                    &:focus{
                        border:none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .inner-top-nav { 
        background-color: transparent; 
        color:  #fff;
        position: absolute;
    }
    .mining_info{
        margin-top:60px;
        position: relative;
        .btn_illustrate{
            position: absolute;
            right: -29px;
            top: -15px;
            background-color: #644FDD;
            border: 1px solid #f0f0f0;
            padding: 5px 10px 5px 20px;
            color: #fff;
            font-size: 16px;
            border-radius: 30px 0 0 30px;
        }
    }
    .btn-outline-primary{
        color:#fff;
        display: block;
        width: 100%;
        border-color: #fff;
        &:hover{
            background-color: transparent;
        }
    }
    h4{
        font-size: 35px;
        margin-bottom: 20px;
        span{
            font-weight: bold;
            font-size:70%
        }
    }
    .col{
        text-align: center;
    }
    .item_title{
        text-align: center;
        font-size: 14px;
    }
    .item_value{
        font-weight: bold;
        font-size: 21px;
        display: block;
        text-align: center;
    }
    .records_item{
        h4{
            font-size: 25px; 
            font-weight: bold;
            span{
                margin-left:10px;
            }
        }
    }
    .mining_lists{
        margin-top:30px;
    }
    .mining_questions{
        margin:30px 0;
        h3{
            font-weight: bold;
            font-size: 21px;
        }
    } 
    .mining_form_action{
        position: absolute;
        bottom:0;
        left: 0;
        right: 0;
        padding:15px;
    }
}
.shop-wrapper{
    .inner-top-nav { 
        background-color: #fff; 
        color: #000;
    }
}

.btn-primary{ 
    background: #644FDD; 
    color:#fff!important;   
    &:hover{ 
        background: #644FDD; 
        opacity: 0.9;
    }
    &:disabled{
        background: #644FDD; 
        opacity: 0.5;
    }
}
.banner{
    margin-bottom:30px;
}
.banner img{
    max-width: 100%;
    max-height: 350px;
    margin:0 auto;
}
.nav-item img{
    max-width: 100%;
    border-radius:30px;
    overflow: hidden;
    max-height: 150px;
}
.order-list-items{ 
    background-color: #F6F6F6;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.shop-wrapper{ 
    .order-list-items{ 
        justify-content: space-between; 
    } 
    .order-list-item-value{ 
        background-color: transparent;
        color: #000; 
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
}
.order-list-item-value{  
    color: #000;
    min-width: 30px;
    height: auto; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.order-list-items:last-child{
    border-bottom: none; 
}
.shop-wrapper{ 
    background-color: $theme-primary-color!important;
    color: $theme-secondary-color!important;
}
.order-action{
    margin:30px 15px
}
.record-lists{
    margin-top:30px;
}
.records_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

.records_item{ 
    margin-bottom:15px;
    position: relative;
    font-family: 'Urbanist', sans-serif; 
    .records_row_title{ 
        font-size:12px; 
    }
    .records_row_value{ 
        font-size:12px;
    }

    .order_status{
        position:absolute;
        top:10px;
        right:10px;
        font-size:10px;
        &.order_0{
            color:#FFB648;
            border:1px solid #FFB648;
            border-radius:5px;
            padding:2px 5px;
            background-color: #ffb64811;
        }
        &.order_1{
            color:#5DD597;
            border:1px solid #5DD597;
            border-radius:5px;
            padding:2px 5px;
            background-color: #5dd59711;
        }
    }
}
.records_row_lists{
    background: #ebebeb;
    border-radius: 5px;
    padding: 5px 10px;
}
.good_lists{
    display: flex;
    margin:5px 0;
    .good_img{
        margin-right:10px;
    }
    .good_info{
        h4{
            font-size:14px;
        }
        h5{
            font-size:12px;
        }
    }
}
.account-header{
    text-align: center;
}
.home{
    .account-header{
        text-align: center;
        h4, h5{
            font-size:16px
        }
    }
}
.home-notice{
    background-color: #1097d7;
    color:#fff;
    border-radius:30px;
    padding:15px;
    margin-bottom:30px
}
.notice-icon{
    margin-right:5px;
    i{
        color:#fdd907;
    }
}
.account-info{
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 15px;
    border-radius: 15px;
    background-color: $theme-primary2-color!important;
    color: $theme-secondary-color!important;

    .account-info-col{
        display: flex; 
        flex-direction: column; 
        button{
            margin-bottom:5px;
        }
    }
}
.loading-text{ 
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    font-size: 21px;
    position: relative;
    z-index: 999;
}
.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(0, 0, 0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px, rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
    padding: 50px 15px 15px;
}
.modal-box-header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 40px;
    background-color: $theme-primary-color!important;
    color: $theme-secondary-color!important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.account-list-items{
    border-bottom: 1px solid rgba(255, 255, 255, 0.431372549);
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
}
 
.form-alert{
    text-align: center;
    color: #000;
    font-size: 12px;
    background: #e1e1e1;
    padding: 10px;
}
 
.module-banner{
    margin:30px;
}
.slider-item{ 
    .slider-item-info{
        margin:0 5px;    
        color: #000; 
        h4{
            font-size:12px;
        }
        .slider-item-image{ 
            border-radius: 10px;
            overflow: hidden; 
            width: 100%;  
            height: 100%;  
            object-fit: cover;
            border: 1px solid #e9e9e9;
            img{
                width: 100%;
            }
        }
    }
} 
.slick-center{
    -ms-transform: scale(1);
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
}
.center .slick-center{
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}
.order-list-item-title{
    font-size: 14px;
    font-family: 'Urbanist', sans-serif;  
    font-weight: 500;
}
.dialog-content{
    text-align: justify;
    font-size:13px!important;
}
.uploadImage{
    max-height:100px;
    margin:15px auto
}
.dialog-wrapper img{
    max-width: 100%;
    text-align: center
}
.brand-logo{
    text-align: center;
    margin-bottom:15px;
    img{
        background-color: #000;
        border-radius:50%;
        margin:0 auto
    }
}
.invite-page{
    text-align: center; 
    height: 90vh;
    background-size: cover!important;
    .invite-page-content{
        padding: 200px 50px 0;
        .invite-box{ 
            background: #fff;
            padding: 30px;
            border-radius: 30px;
        }
    }
}
.paginator {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 15px;
}
.paginator-link.disabled{
    opacity:0.5;
}
.module h4{ 
    font-size: 16px; 
}
.support_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}
.support_info {
    margin-top: 30px;
}
.support_info_title h4 {
    font-size: 18px;
}
.support_info_time {
    font-size: 10px;
}
.support_banner {
    width: 180px;
    margin-bottom: -30px;
}
.module img {
    max-width: 100%;
}
.support_entrance {
    margin-top: 0;
}
.box_wrapper {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.support_entrance_btn {
    margin: 0 30px;
}
.btn-outline {
    color: #000;
    border: 1px solid #000;
    border-radius: 30px;
    font-size: 12px;
}
.box_wrapper {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.user_faq {
    display: block;
    width: 100%; 
    h4 {
        margin: 15px 0;
        font-size: 16px;
    } 
    .user_faq_list {
        margin: 30px 0 50px;
    }
} 

.transaction-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .transaction-info{

        p {
            font-size: 12px!important;
        }
        h3 {
            font-size: 12px!important;
        }
        .type_3,
        .type_5,
        .type_7,
        .type_8,
        .type_9,
        .type_10,
        .type_21,
        .type_22,
        .type_23,
        .type_1{
            color: #ff0000;
        }
        .type_2{
            color: #00c8ff;
        }
        .type_4{
            color: #cf77ff;
        }
        .type_6{
            color: #0cea5b;
        }
    }
}

.report .lf {
    text-align: left;
}
.report{
    margin-bottom:30px;
}
.report div {
    width: 50%;
    height: 65px;
    text-align: left;
    background: #ebebeb ;
    padding: 10px;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: inline-block;
}
.report p {
    color: #9c9c9c;
    font-size: 12px;
    margin-bottom: 5px;
}
.report .rg {
    text-align: right;
}
.downline-info{
    label{
        font-size:11px;
        color: #868686;
    }
    p{
        font-size:12px;
    }
}
#mobileCode{
    text-align: left
}
.footer-icon{ 
    width: 25px;
    height: 25px;
}
.footer-icon.shop{ 
    background: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center; 
}
#chat-widget-container{
    bottom: 40px !important;
    transform: scale(0.8);
}
.wallettype{
    text-transform: uppercase
}
.MuiInput-root{
    border: 1px solid #c3c3c3;
    border-radius: 5px;
    padding: 5px 15px;
    margin-top: 20px;
    background-color: #FAFAFA
}

.MuiFormLabel-asterisk,
.MuiFormControlLabel-asterisk { 
    color: #f00; 
}

.nav-link{
    color:#0668EE; 
}
.page-title{
    margin-top: 15px;
    margin-bottom: 30px;
    h4{
        font-weight: bold
    }
}
.home-header{
    background-color: #fff;
    box-shadow: 0px 0px 2px 1px #0000001f;
    padding:15px;
    border-radius: 10px;
    .home-info-col{
        margin-right:10px;
    }
    .btn-profile{ 
        margin-left:10px;
        background: linear-gradient(30deg, #27CBC0, #0463EF); /* Adjust the colors as needed */
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-size: 20px;
    } 
}
.service-wrapper{
    h4{    
        font-size: 24px;
        font-family: 'Urbanist', sans-serif;  
        font-weight: 600;
    }
    p{    
        font-size: 12px;
        font-family: 'Urbanist', sans-serif;  
        font-weight: 500;
    }
} 

.info-alert{
    background: #ebebeb;
    padding: 10px;
    margin-bottom:15px;
    border-radius: 5px; 
    text-align: center
}
.btn{
    margin:0 auto;
    font-family: 'Urbanist', sans-serif; 
    font-size: 16px;
    font-weight: 500;
    padding:10px 30px;
    &.btn-recharge{
        background-color: #644FDD; 
        margin:0 5px;
    }
    &.btn-withdraw{
        background-color: #644FDD; 
        margin:0 5px;
    }
    span{
        display: block;
        width:100%;
        margin:5px 0;
        color:#fff;
    }
}
.wallet-action{
    margin:15px 0;
    text-align: center;
}
.order-summary{
    font-family: 'Urbanist', sans-serif; 
    box-shadow: 0px 2px 9px 1px #8787873b;
    padding: 20px 10px;
    border-radius: 15px;
    h1{ 
        font-size: 24px;
        font-weight: bold;
        margin:0 0 15px 0;
        border-bottom:1px solid #8d8d8d3b;
        text-align: center;
        padding-bottom:10px;
    }
    .records_row_lists{
        background-color: #F5F5F5;
    }
    .records_item{
        border: none;
    }
    .records_row_value{
        font-weight: bold
    }
    .records_row_title{
        img{
            margin-right:2px;
        }
    }
    .btn{
        border-radius:10px;
        text-transform: capitalize;
        &.btn-danger{
            margin-top:10px;
            border:1px solid #ccc!important;
        }
    }
    .order-summary-action{ 
        border-top: 1px solid #8d8d8d3b;
        padding: 15px 0;
    }
}
.home-wrapper{
    h4{ 
        font-family: 'Urbanist', sans-serif; 
        font-size: 20px;
        font-weight: bold;
        i{
            margin-left:5px;
            font-size: 16px;
        }
    }
    .slick-slide{
        padding:5px 0;
    }
    .slider-mode-center{
        .slick-slide:not(.slick-current) {
            opacity: 0.5;
        }
    }
    .module{
        margin-bottom:30px;
    }
    .notices{
        display: flex;
        justify-content: flex-start;
        .notices-icon{
            margin-right:10px;
            color:#644FDD;
        }
    }
}
.movie-category{
    display: flex;
    justify-content: space-between;
    .movie-category-item{
        background-color: #F0F0F0;
        border-radius:10px;
        margin:0 5px;
        display: flex;
        flex-direction: column;
        padding:20px 30px;
        span{
            margin-top:5px;
            font-family: 'Urbanist', sans-serif; 
            font-size:14px;
            font-weight: bold;
            display: block;
            text-align: center
        }
    }
}
.ff_wrapper{
    position: relative;
    margin:15px  0; 
    h4{
        position: relative;
        z-index: 2; 
    }
    .module-slider{ 
        position: relative;
        z-index: 2;  
        order: 0;
        flex: 0 1 auto;
        align-self: center;
        min-width: 0;
    }
}
.css-1hdyuqk-MuiPaper-root-MuiAlert-root{ 
    position: fixed;
    bottom: 0;
}
.qna-wrapper{
    padding: 0px 0px 100px;
    img{
        max-width: 100%;
        transform: scale(1.1);
    }
}
.form-wrapper{
    h4{
        color:#644FDD;
        font-weight: 600; 
        font-size: 21px;
        line-height: 6vw;
        text-align: left;
    }
}


.vip_list_header{
    h4{ 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span{
            margin-left:5px;
        }
    }
}

.card-wrapper{
    background-color: #fff;
    padding:10px;
    border-radius: 10px;
    margin: 10px 0;
    box-shadow: 0px 2px 6px 1px #00000024; 
    h4{
        font-weight:bold;
        margin:15px 0 0;
    }
    p{
        color: #8b9592;
        font-size:14px;
    }
    .card-wrapper-footer{ 
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ccc;
        margin:15px 0 0;
        padding:10px 0 0;
    }
    .card-wrapper-header{
        display: flex;
        justify-content: space-between;
        h4{
            font-size:18px;
        }
        .filter{
            width:80px;        
            text-align: right;
            border:1px solid #ccc;
            border-radius: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    
    .card-wrapper-body{ 
        .item_title{ 
            color: #9ba7b2;
            font-size: 16px;
            margin:0;
            height: 40px;
            align-items: center;
            display: flex;
            font-weight: normal;
            text-align: center;
        }
        .item_value{
            font-weight: normal;
            font-size: 16px;
            height: 40px;
            align-items: center;
            display: flex;
            justify-content: center;
            line-height: 100%;
            margin:0;
        }
        .team-row{
            margin:10px 0;
            padding:10px 0;
            &:nth-child(2){
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
            }
            h5{
                font-weight: bold;
                font-size: 18px;
                color: #644FDD;
            }
        }
    }
}
.record-lists{
    min-height:200px;
    .records_tabs{
        margin:30px 0 0 0;
    }
}
.list-row{
    display: flex;
    justify-content: space-between;
    .list-row-title{
        text-align: left;
        color: #8b9592;
        font-size:14px;
    }
    .list-row-value{
        text-align: right
    }
}
.rv-cell{
    padding: 10px 7px;
}
.rv-field__children{
    text-overflow: ellipsis;
    white-space: nowrap; 
    width: 75px;
}
.transaction-row{
    margin: 10px 0;
    box-shadow: 0px 2px 2px 1px rgb(0 0 0 / 8%);
    background: #fff;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    .transaction-info{
        p{
            margin:0;
        }
    }
}

.inner-tab-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin:15px 0;
    .nav-tab{ 
        text-align: center;
        width: 120px;
        background-color: #F0F0F0!important;
        border-radius: 10px;
        padding: 10px 15px;
        color: $theme-secondary-color!important;
        margin:0 5px;
        font-family: 'Urbanist', sans-serif; 
        font-size: 14px;
        font-weight: bold;
    }
    .nav-tab.active{
        background-color: #644FDD!important;
        border: 1px solid #644FDD!important;
        color:#fff!important;
    }
}

/* CSS */ 

@import 'login'; 
@import 'footer'; 
@import 'home'; 
@import 'register'; 
@import 'profile'; 
@import 'vip'; 
@import 'withdraw'; 
