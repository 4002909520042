
.top-nav{
    position: fixed;
    top:0;
    left:0;
    right:0;
    height:60px;
    background-color: $theme-primary-color;
    text-align: center;
    box-shadow: 1px 1px 6px 2px #0000002e;
    z-index: 9;
}
.theme-box{ 
    color: $theme-secondary-color;
    background-color: $theme-primary2-color;
    padding:15px;
    border-radius: 15px;
    text-align: center;
}
.module{
    margin:20px 0; 
}

.btn-start  {
    display: block;
    text-align: center;
}
.btn-start img{
    max-width: 100%;
    max-height: 120px;
    margin:0 auto;
}
.quict-link-item{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    span{
        font-size:13px;
        margin-top:10px
    }
}
.theme-box.account-summary{
    .quict-link-item{ 
        align-items: start;
        padding:15px;
        border-radius: 15px;
        background-color: #fff;
        color: #000;
        .label{
            color: $theme-primary-color;
        }
    }
}

.btn-ticket:hover, .btn-ticket {
    background-color: #6D6DFF;
    border-radius: 30px;
    padding: 2px 10px;
    color: #fff;
    font-size: 11px;
    margin: 5px auto;
    display: inline-block;
    width: 80px;
}
.module-slider img {
    width: 100%;
    object-fit: cover;
}
.module-slider h4 { 
    margin: 10px 0 0;
    text-align: center;
    font-size: 11px;
    height: 40px;
}
.home-header{
    display: flex;
    justify-content: space-between;
    .home-info-col{ 
        display: flex;
        justify-content: center;
        flex-direction: column; 
        h4{
            font-size:31px;
            margin:5px 0 10px 0;
            font-weight: bold;
        }
        h5{
            margin:5px 0 0 0;
            font-size:16px;
            font-weight: normal;
            color: #b7b5c3;
        }
        &.avatar{
            margin-top:-50px;
            img{    
                background-color: #fff;
                border-radius: 50%;
            }
        }
        .drawer-copy-btn{
            margin-left:5px;
        }
    }
}

.wallet-info{
    text-align: center;
    h3{
        font-size:21px;
        margin:20px 0;
    }
    h4{
        font-size:16px;
        margin:20px 0;
    }
}
.quant-log{
    .col{   
        .item_title {
            font-weight: normal;
            font-size: 13px; 
        }
        .item_value {
            font-weight: normal;
            font-size: 18px; 
        }
    }
    .left{ 
        .item_title,
        .item_value {
            text-align: left;
        }
    }
    .center{
        .item_title,
        .item_value {
            text-align: center;
        }
    }
    .right{
        .item_title,
        .item_value {
            text-align: right;
        }
    }
    .quant-log-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom:15px;
        .link-status{
            color:#03AD90
        }
        h4{
            font-size:21px;
            font-weight:bold;
            img{
                margin-right:10px
            }
        }
    }
        
}