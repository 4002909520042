 
.text-center {
    text-align: center!important;
}
.invite-banner {
    position: relative;
    max-width:360px;
    margin:0 auto
}
.invite-banner .invite-info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 120px;
    right: 20px;
    justify-content: space-between;
    display: flex;
}
.invite-info span {
    font-size: 18px;
    margin: 0;
    line-height: 18px;
    color: #804F2D;
    font-weight: bold;
}
.btn-invite { 
    border-radius: 30px;
    padding: 5px 10px;
    min-width: 120px;
}
.quick-nav { 
    border-radius: 10px; 
    padding: 15px;
}
.quick-nav-row {
    display: flex;
    justify-content: space-around;
}
.quick-nav-item {
    display: inline-block;
    padding: 15px 0;
    width: 80px;
    text-align: center;
} 
.quick-nav-item span {
    height: 30px;
    font-size: 11px;
    margin-top: 5px;
    display: block;
}
.btn-contact {
    position: relative;
    background: #85858526;
    border-radius: 10px; 
    height: 50px;
    display: flex;
    padding: 10px 10px 10px 65px;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
}

.btn-contact img {
    max-height: 40px;
}

.btn-contact span {
    font-size: 12px;
    margin-right: 5px;
    margin-top: 10px;
}
a {
    color: #fff;
    text-decoration: none;
}

.my-wrapper{

    .module{ 
        padding:0;
    }
} 
.profile_banner{
    height: 140px;
    width: auto;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: self-start;
    padding: 30px 50px;
    .banner_info{
        color:#fff;
        margin-left:15px;
        h2{
            font-size: 16px;
            font-weight:bold;
        }
        h4{
            font-size: 14px;
            font-weight:normal;
        }
    }
}
.card{
    .item_value{ 
        font-weight: bold;
        font-size: 26px;
        display:block;
        width:100%;
        text-align: center;
    }
    .item_title{
        font-weight: normal;
        font-size: 12px;
        color:#999;
        display:block;
        width:100%;
        text-align: center;
    }
}  
.card-vip{
    margin:30px 0;
    border-radius:5px;
    overflow: hidden;
    box-shadow: 0px 1px 4px #00000063;
    .card-vip-header{
        background-image: linear-gradient(to right,#FCCE83,#CF9C5B);
        padding: 4vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .vip-info{
            h4{ 
                color: #935733;
                font-weight: normal;
                font-size:16px;
            }
            h3{ 
                color: #935733;
                font-weight: bold;
                font-size:25px;
            }
            h5{ 
                color: #935733;
                font-weight: normal;
                font-size:16px;
            }
        }
    }
    .card-vip-info{
        padding: 2.666667vw 4vw;
        background-image: linear-gradient(45deg,#fddbaa,#D8AD78);
        box-shadow: 0 0 2.666667vw #d7a968;
        justify-content: center;
        align-items: center;
        color:#fff;
        .item_value{  
            font-size:21px;
            display: block;
            text-align: center;
            width:100%;
        }
        .item_title{  
            font-size:16px;
            display: block;
            text-align: center;
            width:100%;
        }
    }
}

.app_wrapper{ 
    margin: 0 auto;
    text-align: justify;
}

.profile_nav{
    border:1px solid #00000042;
    border-radius:15px;
    padding:15px;
    margin:0 0 15px;
    h4{        
        font-family: 'Urbanist', sans-serif; 
        font-size: 20px;
        font-weight: bold;
    }
    ul{
        padding:0;
        margin: 0;
        li{
            list-style: none;
            span.nav-link{
                color: #000;
                padding: 10px 5px;
                display: flex;
                border-bottom: 1px solid #00000042;
                align-items: center;
                justify-content: space-between;
                .nav-icon{
                    display: flex;
                    width:30px;
                    height:30px;
                    border-radius:50%;
                    justify-content: center;
                    align-items: center;
                    background-color: #F6F6F6;
                    color:#000;
                    margin-right:15px;
                    i{
                        font-size:14px;
                    }
                }
                .nav-title{
                    display: flex;
                    align-items: center;
                }
                .nav-badge{
                    font-size:12px;
                }
            }
            &:last-child{
                span.nav-link{
                    border-bottom:none;
                }
            }
        }
    }
}